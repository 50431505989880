<template>
    <nav class="navbar navbar-expand-lg justify-content-between"
         :class="[suggestionViewerType, accountFeatures.newCollectionUx ? 'newUx' : '']"
         v-show="navMode !== 'none'">
        <div class="container ms-4 me-4 ps-0 pe-0">
            <router-link class="navbar-brand text-decoration-none" to="/">
                <span @click="refreshIfOnMainScreen">
                    <app-logo></app-logo>
                </span>
            </router-link>

            <template v-if="!accountFeatures.newCollectionUx">
                <button
                    v-if="currentAccount && suggestionViewerType === 'topics' && showContentWorkflow"
                    @click="showTopicBuilder"
                    class="d-block ms-auto d-xl-none btn btn-danger btn-sm me-lg-3"
                >
                    <i class="fa fa-plus"></i> New Topic
                </button>

                <button
                    v-if="currentAccount && suggestionViewerType === 'collections' && showContentWorkflow"
                    @click="showCollectionModal"
                    class="d-block ms-auto d-xl-none btn btn-danger btn-sm me-lg-3"
                >
                    <i class="fa fa-plus"></i> New Collection
                </button>
            </template>
            <template>
                <back-to-button  v-if="showBackToCollectionLink" class=""></back-to-button>
            </template>

            <!-- Used for the Chrome Add On-->
            <span class="small float-end text-light btn-group btn-group-sm"
                  v-if="navMode === 'minimal' && loggedInUser">
                <router-link class="btn btn-primary" :to="{name : 'Logout'}" style="color: var(--bs-default)">Log out</router-link>

                <a href="https://localhost:8080" target="_blank" class="btn btn-primary">
                    Go to {{appName}}
                </a>
            </span>

            <user-navigation-items
                v-if="currentAccountOverview"
                class="navbar-nav d-none d-lg-flex align-items-center ms-3"
                :topic="topic"
                :isNonFreeAccount="isNonFreeAccount"
            />
        </div>
        <div class="container d-block d-lg-none ms-4 me-4 p-0" v-if="currentAccountOverview">
            <user-navigation-items
                class="navbar-nav d-flex align-items-center flex-row justify-content-end flex-grow"
                :topic="topic"
                :isNonFreeAccount="isNonFreeAccount"
            />
        </div>
    </nav>
</template>

<script>


import {mapGetters} from 'vuex';
import UserNavigationItems from "@/components/Navigation/UserNavigationItems"
import UpcontentLogo from '@/components/Navigation/Logo'
import RouteContext from '@/utilities/RouteContext'
import BackToButton from "../components/Suggestions/ManageSuggestion/BackToButton";
import AppLogo from "../components/Navigation/AppLogo";

export default {
    components: {
        AppLogo,
        BackToButton,
        UserNavigationItems, UpcontentLogo
    },
    mixins: [RouteContext],
    computed: {
        ...mapGetters({
            isDealerVenom: 'isDealerVenom',
            isHearsay: 'isHearsay',
            isSocialAdvisors: 'isSocialAdvisors',
            vendor: 'vendor',
            subvendor: 'subvendor',
            loggedInUser: 'currentUser',
            currentAccountOverview: 'currentAccountOverview',
            accounts: 'allAccounts',
            loadingAccounts: 'accountsBeingLoaded',
            folders: 'folders',
            topics: 'topics',
            topic: 'currentTopic',
            subscriptions: 'subscriptions',
            features: 'features',
            appByUpcontent: 'appByUpcontent',
            'suggestionViewerType': 'suggestionViewerType',
            subscriptionPlan: 'subscriptionPlan',
            isAmplify: 'isAmplify',
            isEmbed: 'isEmbed',
            hasSuggestions: 'hasSuggestions',
            accountFeatures: 'accountFeatures',
            appName: 'appName',
        }),
        isNonFreeAccount() {
            return this.subscriptionPlan;
        },
        showBackToCollectionLink() {
            return this.navFull &&  this.$route.name !== 'Suggestions' && this.hasSuggestions && this.hasSuggestions.id;
        },
    },
    methods: {

        showTopicBuilder() {
            window.Events.$emit('showQueryBuilderModal', {topic: false});
        },
        showCollectionModal() {
            window.Events.$emit('showCollectionCreationModal');
        },
        refreshIfOnMainScreen() {
            if (this.$route.name === 'Suggestions') {
                window.location.reload();
            }
        }
    }
}
</script>

<style scoped>
.navbar {
    transition: background-color 600ms ease-out;
}

.dealer-venom-logo {
    width: 198px;
    height: 25px;
}

.social-adv-logo {
    width: 152px;
    height: 30px;
}
</style>
